import RouterPage from "./router/RouterPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
	return (
		<div className="">
			<RouterPage />
			<ToastContainer />
		</div>
	);
}

export default App;
